import { Alert, Snackbar } from '@mui/material';
import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { RootState } from 'store';


export const SnackBarMessage = () => {

    const { errorMessage, successMessage } = useSelector((state: RootState) => state.status);

    return (
        <>

            <Snackbar open={errorMessage !== null} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                {
                    <Alert severity="error" sx={{ width: '100%' }} >
                        {errorMessage && (
                        <div dangerouslySetInnerHTML={{__html: errorMessage}}/>)}
                        
                    </Alert>
                }
            </Snackbar>

            <Snackbar open={successMessage !== null} autoHideDuration={6000} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                {
                    <Alert severity="success" sx={{ width: '100%' }} >
                        {successMessage}
                    </Alert>
                }
            </Snackbar>

        </>

    )
}
