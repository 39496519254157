import { useHousingSubsidy } from "hooks/useHousingSubsidy";
import { useParam } from "hooks/useParam";
import { MPostulationHousingSubsidyRequest } from "models/postulationModel";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "store";
import {
  CAMPO_S,
  HOUSING_ENTITLEMENT_ID,
  PARAM_INFO_RADICACION_SUB_VIVIENDA,
  PARAM_URL_FORM_POSTULACION_VIVIENDA,
  PARAM_URL_VIDEO_POSTULACION_VIVIENDA,
  PASSPORT_DOCUMENT_TYPE,
} from "utils/constants";
import { SmallLoading } from "components/SmallLoading";
import { Card } from "@mui/material";
import { FormCardPostulation } from "./FormCardPostulation";
import { PostulationInfo } from "./PostulationInfo";
import { Category } from "models/category";
import moment from "moment";
import manosComfenalco from "../../../assets/images/manosComfenalco.png";
import { IsCategoryAvailable } from "utils/CategoryAvailable";


interface HousingApplicationProps {
  color: string;
  selectedCategory?: Category;
}

export const HousingApplication = ({ color, selectedCategory }: HousingApplicationProps) => {
  const { isLogged, authData } = useSelector((state: RootState) => state.auth);
  const { getOpportunitiesPostulation, isLoadingOpportunitiesPostulation } =
    useHousingSubsidy();
  const { getParamData } = useParam();
  const [isLoading, setIsLoading] = useState(false);
  const [postulationVideoUrl, setPostulationVideoUrl] = useState<string>();
  const [postulationFormUrl, setPostulationFormUrl] = useState<string>();
  const [postulationInfoMessage, setPostulationInfoMessage] =
    useState<string>();
  const userRights: any[] = authData != null ? authData.derechoVOs : [];
  const [message, setMessage] = useState<string>();
  const [shortMessage, setShortMessage] = useState<string>();
  const [showMessage, setShowMessage] = useState(false);
  const [canApply, setCanApply] = useState(false);

  const isWithinRange = selectedCategory && IsCategoryAvailable(selectedCategory)

  const messages = [
    {
      id: 1,
      msg: "Usuario no está activo o no está como calidad de trabajador.  Para mayor información comunicarse al   602 8862727 ext. 3415 o agendar cita virtual para asesoría al respecto.",
      description: "calidadTrabajo.",
    },
    {
      id: 2,
      msg: "En este momento no puede gestionarse su postulación por presentar una inconsistencia en información de afiliación.  Por favor comunicarse al 602 8862727 ext. 3415 o agendar cita virtual para asesoría al respecto.",
      description: "Presentas una inconsistencia.",
    },
    {
      id: 3,
      msg: "Usted ya cuenta con una postulación radicada. Para mayor información comunicarse al   602 8862727 ext. 3415 o agendar cita virtual para asesoría al respecto.",
      description: "Tienes una postulación radicada.",
    },
    {
      id: 4,
      msg: "De acuerdo a verificación de su información contra registro de    los datos de su afiliación, se presenta una inconsistencia que  no permite realizar radicación de postulación en este momento.  Para mayor información comunicarse al   602 8862727 ext. 3415 o agendar cita virtual para asesoría al respecto.",
      description: "Presentas una inconsistencia.",
    },
    {
      id: 5,
      msg: "Para la radicación de postulación a subsidio de vivienda  sólo son válidos como documentos de identificación cédula   de ciudadanía o cédula de extranjería para mayores de edad  y tarjeta de identidad o registro civil para menores de edad    Para mayor información comunicarse al   602 8862727 ext. 3415 o agendar cita virtual para asesoría al respecto.",
      description: "Tipo de documento inválido para postulación.",
    },
  ];

  useEffect(() => {
    const getOportunities = async () => {
      getInfoParams();

      if (authData && isLogged) {
        const tipoIdentificacion = authData?.afiliado?.abreviatura;
        const identificacion = authData?.afiliado?.identificacion;

        const opportunitiesRequest: MPostulationHousingSubsidyRequest = {
          identificacion,
          tipoIdentificacion,
        };
        const filteredRights = userRights.filter(
          (right) =>
            right.idDere === HOUSING_ENTITLEMENT_ID &&
            right.servicio === CAMPO_S
        );
        const hasHousingEntitlement = filteredRights.length > 0;
        if (hasHousingEntitlement) {
          setCanApply(true);
          setMessage(
            "Puede continuar con el proceso de registro de sus datos para postulación a subsidio de vivienda"
          );
          setShortMessage("Ingresar al formulario");
          setShowMessage(true);
        } else {
          setCanApply(false);
          setMessage(messages[1].msg);
          setShortMessage(messages[1].description);
          setIsLoading(false);
          return;
        }
        if (tipoIdentificacion === PASSPORT_DOCUMENT_TYPE) {
          setCanApply(false);
          setMessage(messages[4].msg);
          setShortMessage(messages[4].description);
          setIsLoading(false);
          return;
        }
        const opportunities = await getOpportunitiesPostulation(
          opportunitiesRequest
        );
        if (!opportunities) {
          setCanApply(true);
          setMessage(
            "Puede continuar con el proceso de registro de sus datos para postulación a subsidio de vivienda"
          );
          setShortMessage("Ingresar al formulario");
          setShowMessage(true);
        } else {
          setCanApply(false);
          setMessage(messages[2].msg);
          setShortMessage(messages[2].description);
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    };

    getOportunities();
  }, []);

  const getInfoParams = async () => {
    setIsLoading(true);
    const noHousingInformationParam = await getParamData(
      PARAM_URL_VIDEO_POSTULACION_VIVIENDA
    );
    const formHousingUrlParam = await getParamData(
      PARAM_URL_FORM_POSTULACION_VIVIENDA
    );
    const formHousingInfoParam = await getParamData(
      PARAM_INFO_RADICACION_SUB_VIVIENDA
    );
    setPostulationVideoUrl(noHousingInformationParam?.data?.valorParametro);
    setPostulationFormUrl(formHousingUrlParam?.data?.valorParametro);
    setPostulationInfoMessage(formHousingInfoParam?.data?.valorParametro);
  };

  return (
    <>
      {authData && isLogged ? (
        <>
          {isLoading || isLoadingOpportunitiesPostulation ? (
            <div>
              <SmallLoading />
            </div>
          ) : (
            <>
              {
                !isWithinRange ?
                  <div className="flex flex-wrap border rounded-lg shadow p-4 m-4">
                    <div className="flex w-full md:w-1/3 lg:w-1/3">
                      <FormCardPostulation
                        canApply={canApply}
                        postulationMessage={message ?? ""}
                        postulationShortMessage={shortMessage ?? ""}
                        color={color}
                        formHousingUrl={postulationFormUrl!}
                      />
                    </div>

                    {showMessage && (
                      <div className="flex w-full md:w-2/3 lg:w-2/3">
                        <PostulationInfo
                          postulationVideoUrl={postulationVideoUrl!}
                          message={postulationInfoMessage ?? ""}
                        />
                      </div>
                    )}
                  </div> :


                  <div className="flex flex-col w-full items-center pt-4">
                    <div className="flex flex-col w-2/3 items-center rounded-lg text-center bg-principal-350 p-2">
                      <img
                        src={manosComfenalco}
                        className="h-16 w-16"
                      />
                      <p className="text-principal-600 font-bold">
                        {selectedCategory?.mensajeInactividad && selectedCategory?.mensajeInactividad}
                      </p>
                    </div>
                  </div>
              }
            </>

          )}
        </>
      ) : (
        <div className="flex flex-wrap border rounded-lg shadow p-4 m-4">
          <PostulationInfo
            postulationVideoUrl={postulationVideoUrl!}
            message={postulationInfoMessage ?? ""}
          />
        </div>
      )
      }
    </>
  );
};
