import React, { ChangeEvent, ChangeEventHandler, useEffect, useState } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Button,
  Container,
  SelectChangeEvent,
} from "@mui/material";
import { FieldErrors, UseFormGetValues, UseFormRegister, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { PurchaseForm, ReservationForm } from "models/purchaseForm";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useGenericData } from "hooks/useGenericData";
import moment, { Moment } from 'moment';
import 'moment/locale/es';
import { useBoxValidator } from "hooks/useBoxValidator";
import { setDate } from "date-fns";
import { useUser } from "hooks/useUser";
import { MAYORIA_EDAD_HOTEL } from "utils/constants";

interface AditionalFormProps {
  register: UseFormRegister<ReservationForm[]>;
  formIndex: number;
  errors: FieldErrors<ReservationForm[]>;
  setValue: UseFormSetValue<ReservationForm[]>;
  watch: UseFormWatch<ReservationForm[]>,
  getValue: UseFormGetValues<ReservationForm[]>;
}

export const HotelAditionalForm = ({
  register,
  formIndex,
  errors,
  setValue,
  watch,
  getValue
}: AditionalFormProps) => {

  const [disableFields, setDisableFields] = useState<boolean>(false);
  const [disableFieldGender, setDisableFieldGender] = useState<boolean>(false);
  const [dateSelected, setDateSelected] = useState<any>();
  const [gendero, setGender] = useState("");

  moment.locale('es-co');

  const selectedBirthDate = watch(`${formIndex}.birthDate`);

  const [identificationTypes, setIdentificationTypes] = useState<any[]>([]);
  const [identificationTypesToshow, setIdentificationTypesToShow] = useState<any[]>([]);
  const [fullName, setFullName] = useState('');
  const { getIdentificationsTypes } = useGenericData();

  const selectedDocumentTpeWatched = watch(`${formIndex}.documentType`);
  const documentNumberWatched = watch(`${formIndex}.documentNumber`);

  const { getBoxValidator, isLoadingBoxValidator } = useBoxValidator();
  const { consultaInfoFormParticular } = useUser();

  useEffect(() => {
    const onGetGetIdentificationsTypes = async () => {
      const identificationTypesResp = await getIdentificationsTypes();
      setIdentificationTypes(
        identificationTypesResp?.identificationTypes
          ? identificationTypesResp?.identificationTypes
          : []
      );
      setIdentificationTypesToShow(
        identificationTypesResp?.identificationTypes
          ? identificationTypesResp?.identificationTypes
          : []
      )
    };
    onGetGetIdentificationsTypes();
  }, []);

  const handleDateChange = (date: Date | null) => {
    // Con setValue, actualizamos el valor del campo 'selectedDate'

    setValue(`${formIndex}.birthDate`, date);

    const currentDate = moment();
    const birthDate = moment(date);

    const ageDifference = currentDate?.year() - birthDate.year();
    let filteredIdentificationTypes = identificationTypes;

    if (ageDifference < MAYORIA_EDAD_HOTEL) {
      filteredIdentificationTypes = filteredIdentificationTypes.filter(
        identification =>
          identification?.ABREVIATURA !== 'CC' &&
          identification?.ABREVIATURA !== 'TI'
      );
    } else if (ageDifference < 18) {
      filteredIdentificationTypes = filteredIdentificationTypes.filter(
        identification =>
          identification?.ABREVIATURA !== 'CC' &&
          identification?.ABREVIATURA !== 'RC'
      );
    } else {
      filteredIdentificationTypes = filteredIdentificationTypes.filter(
        identification =>
          identification?.ABREVIATURA !== 'TI' &&
          identification?.ABREVIATURA !== 'RC'
      );
    }

    setIdentificationTypesToShow(filteredIdentificationTypes);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // Permitir solo números
    if (event.key !== 'Backspace' && isNaN(Number(event.key))) {
      event.preventDefault();
    }
    if (event.key === 'Enter' || event.key === 'Tab') {
      handleChangeDocumentTypeAndDocumentNumberBlur();
    }
  };

  const handleChangeDocumentTypeAndDocumentNumberBlur = async () => {
    //clean all fields
    setValue(`${formIndex}.birthDate`, null);
    setDateSelected(null);
    setValue(`${formIndex}.gender`, '', { shouldDirty: false });
    setGender('');
    setValue(`${formIndex}.fullName`, '', { shouldDirty: false });
    setFullName('');
    setDisableFieldGender(false);
    setDisableFields(false);
    //find user info
    if (selectedDocumentTpeWatched && documentNumberWatched) {
      let getInfoUser = await consultaInfoFormParticular(selectedDocumentTpeWatched, documentNumberWatched);
      setDisableFields(false);
      if (getInfoUser) {

        let nombre = getInfoUser.nombre ?? '';
        let genero = getInfoUser.genero ? getInfoUser.genero.toString().trim().toUpperCase() : '';
        if(nombre === '') {        
          setDisableFields(false);
        }else{
          setDisableFields(true);
        }

        if (getInfoUser?.fechaNacimiento) {

          let fechaNacimiento = moment(getInfoUser.fechaNacimiento);
          setValue(`${formIndex}.birthDate`, fechaNacimiento);
          setDateSelected(fechaNacimiento);
        }else{
          setDisableFields(false);
        }
        setFullName(nombre);

        // disable all fields
        setValue(`${formIndex}.fullName`, nombre, { shouldDirty: true });

        if (genero) {
          setValue(`${formIndex}.gender`, genero, { shouldDirty: true });
          setGender(genero);
          setDisableFieldGender(true);
        }
      }


    }
  }

  const handleChangeGender = (event: SelectChangeEvent) => {
    setValue(`${formIndex}.gender`, event.target.value, { shouldDirty: true })
    setGender(event.target.value);
  };

  const maskName = (name: string): string => {
    return name
      .split(" ")
      .map(word => word.length > 3 
        ? word.substring(0, 3) + "*".repeat(word.length - 3) 
        : word
      )
      .join(" ");
  };
  
  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const maskedName = maskName(event.target.value);
    setValue(`${formIndex}.fullName`, maskedName, { shouldDirty: true });
    setFullName(event.target.value);
  };

  return (
    <div className="flex flex-wrap w-full py-3">
      <span className="font-bold text-xl text-principal-500 w-full">
        Identificate para acceder al servicio
      </span>
      <div className="w-full sm:w-1/2 px-1 py-3">
        <FormControl className="w-full">
          <InputLabel id="tipo-identificacion">
            Tipo de identificación
          </InputLabel>
          <Select
            labelId="tipo-identificacion"
            label="Tipo de identificación"
            defaultValue={""}
            {...register(`${formIndex}.documentType`, {
              required: "El tipo de identificación es obligatorio",
            })}
            onBlur={() => handleChangeDocumentTypeAndDocumentNumberBlur()}
          >
            {identificationTypesToshow?.map((id, index) => (
              <MenuItem key={index} value={id?.ABREVIATURA}>
                {id?.DESCRIPCION}
              </MenuItem>
            ))}
          </Select>
          {errors[formIndex]?.documentType && (
            <p className="text-red-500">
              {String(errors[formIndex]?.documentType?.message)}
            </p>
          )}
        </FormControl>
      </div>

      <div className="w-full sm:w-1/2 px-1 py-3">
        <TextField
          defaultValue={""}
          className="w-full"
          label="Número de documento"
          {...register(`${formIndex}.documentNumber`, {
            required: "El número de documento es obligatorio",
          })}
          onKeyDown={handleKeyDown}
          onBlur={() => handleChangeDocumentTypeAndDocumentNumberBlur()}
        />
        {errors[formIndex]?.documentNumber && (
          <p className="text-red-500">
            {String(errors[formIndex]?.documentNumber?.message)}
          </p>
        )}
      </div>

      <div className="w-full sm:w-1/3 px-1 py-3">
        <FormControl className="w-full">


          <TextField
            label="Nombre completo"
            disabled={disableFields}
            id="fullName"
            value={maskName(getValue(`${formIndex}.fullName`) ?? "")}
            className="w-full"
            {...register(`${formIndex}.fullName`, {
              required: "El nombre es obligatorio",
            })}
            onChange={handleChangeName}
          />
          {errors[formIndex]?.fullName && (
            <p className="text-red-500">
              {String(errors[formIndex]?.fullName?.message)}
            </p>
          )}
        </FormControl>

      </div>
      <div className="w-full sm:w-1/3 mt-3 px-1">
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            disabled={disableFields}
            className="w-full"
            format="DD/MM/YYYY"
            disableFuture={true}
            label="Fecha de nacimiento"
            value={getValue(`${formIndex}.birthDate`) ?? moment()}
            slots={{
              ...register(`${formIndex}.birthDate`, {
                required: "La fecha de nacimiento es obligatoria",
              }),
            }}
            onChange={handleDateChange}
          />
        </LocalizationProvider>
        {errors[formIndex]?.birthDate && (
          <p className="text-red-500">
            {String(errors[formIndex]?.birthDate?.message)}
          </p>
        )}
      </div>


      <div className="w-full sm:w-1/3 px-1 py-3">
        <FormControl className="w-full">
          <InputLabel id="gender">Género</InputLabel>
          <Select
            disabled={disableFieldGender}
            value={getValue(`${formIndex}.gender`) ?? ''}
            labelId="gender"
            {...register(`${formIndex}.gender`, {
              required: "El género es obligatorio",
            })}
            defaultValue={getValue(`${formIndex}.gender`)}
            onChange={handleChangeGender}
          >
            <MenuItem key={'M'} value="M">Masculino</MenuItem>
            <MenuItem key={'F'} value="F">Femenino</MenuItem>
          </Select>
        </FormControl>
        {errors[formIndex]?.gender && (
          <p className="text-red-500">
            {String(errors[formIndex]?.gender?.message)}
          </p>
        )}
      </div>

    </div>
  );
};
