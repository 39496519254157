import { LoadingButton } from '@mui/lab'
import { Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { useGenericData } from 'hooks/useGenericData'
import { useUser } from 'hooks/useUser'
import { InfoUsuarioFormLight } from 'models/infoUsuarioFormLight'
import { MLightForm } from 'models/lightForm'
import React, { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import { AdmissionData, RateFormLight, ServiceDetailLight } from 'models/lightForm';
import { useReCaptcha } from 'hooks/useReCaptcha'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { CustomDialog } from 'components/Dialog'
import { useParam } from 'hooks/useParam'
import { useShoppingCar } from 'hooks/useShoppingCar'


interface AdmissionFormProps {
    setStep: React.Dispatch<React.SetStateAction<number>>,
    disableButton: boolean,
    setAdmissionData: React.Dispatch<React.SetStateAction<any>>,
    setdataUserNotFound: React.Dispatch<React.SetStateAction<InfoUsuarioFormLight>>
    setPreviousstep: React.Dispatch<React.SetStateAction<number>>
    serviceDetail?: ServiceDetailLight,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
    validaRecapchat: boolean
}

export const AdmissionForm = ({ setStep, disableButton, setAdmissionData, setdataUserNotFound, setPreviousstep, serviceDetail, setLoading, validaRecapchat }: AdmissionFormProps) => {

    const { handleSubmit, register, formState: { errors } } = useForm<MLightForm>();

    const { getIdentificationsTypes } = useGenericData();

    const [identificationTypes, setIdentificationTypes] = useState<any[]>([]);

    const { getInfoUsuarioFormLight } = useUser();

    const { validateTokenRecaptcha } = useReCaptcha();

    const { executeRecaptcha } = useGoogleReCaptcha();

    const [open, setOpen] = React.useState(false);

    const [errorMessage, setErrorMessage] = useState<string>("");

    const [recaptchaAlreadyValidated, setRecaptchaAlreadyValidated] = useState(false);
    const [errorRecaptcha, setErrorRecaptcha] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [mensajeServicioAdquirido, setMensajeServicioAdquirido] = useState('');
    const { paySale } = useShoppingCar();
    const [idCompra, setIdCompra] = useState(0); 
    

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        if (deviceType !== 'desktop') {
            window.location.href = 'com.delagenteapp://path/to/resource';
        } else {
            handleNavigate('/home')
        }
        setOpen(false);
    };
    const [deviceType, setDeviceType] = useState('desktop');

    useEffect(() => {
        const userAgent = navigator.userAgent.toLowerCase();
        if (/mobile|android|iphone|ipad|phone/i.test(userAgent)) {
            setDeviceType('mobile');
        } else {
            setDeviceType('desktop');
        }
    }, []);

    const navigate = useNavigate();

    const handleNavigate = (rute: string) => {
        navigate(rute);
    };

    useEffect(() => {

        const onGetGetIdentificationsTypes = async () => {
            const identificationTypesResp = await getIdentificationsTypes();
            setIdentificationTypes(
                identificationTypesResp?.identificationTypes
                    ? identificationTypesResp?.identificationTypes
                    : []
            );
        };
        onGetGetIdentificationsTypes();
    }, []);

    useEffect(() => {
        doValidateRecaptcha();
    }, [executeRecaptcha]);

    const doValidateRecaptcha = async () => {
        
        if (validaRecapchat == false){
            setRecaptchaAlreadyValidated(true);
            setErrorRecaptcha(false);
            return
        }
        ////console.log('do validate recaptcha')

        if (!executeRecaptcha) return;
        let token = await executeRecaptcha('submit');
        const validateTokenResponse = await validateTokenRecaptcha(token);

        if (validateTokenResponse?.success) {
            setRecaptchaAlreadyValidated(true);
            setErrorRecaptcha(false);
        } else {
            setRecaptchaAlreadyValidated(false);
            setErrorRecaptcha(true);
        }

    }

    const handleFormSubmit: SubmitHandler<MLightForm> = async (
        data: MLightForm
    ) => {
        if (data) {
            setLoading && setLoading(true);
            await doValidateRecaptcha();
            //validate recaptcha
            if (recaptchaAlreadyValidated) {
                let infoUsuarioFormLightData: InfoUsuarioFormLight = {
                    numeroIdentificacion: Number(data.documentNumber),
                    tipoIdentificacion: data.documentType,
                    idServicio: Number(serviceDetail?.idServicio)
                };
                const infoUsuarioFormLight = await getInfoUsuarioFormLight(infoUsuarioFormLightData);
                setLoading && setLoading(false);

                if (infoUsuarioFormLight?.error === true) {
                    // ED significa
                    if (infoUsuarioFormLight?.code === 'ED') {
                        setErrorMessage(infoUsuarioFormLight?.message);
                        handleClickOpen();
                    }
                } else {
                    console.log(infoUsuarioFormLight);
                    if (infoUsuarioFormLight?.tieneServicio === true) {
                        // Guarda la información y abre el diálogo
                        const getMensajeServicioAdquirido = await getParamData("MENSAJE_SERVICIO_ADQUIRIDO");
                        if (getMensajeServicioAdquirido?.data) {
                            const dataParameter = getMensajeServicioAdquirido?.data;
                            setMensajeServicioAdquirido(dataParameter.valorParametro);
                        }
                        setIdCompra(infoUsuarioFormLight.idCompra);
                        setOpenDialog(true);
                        return; // Detenemos la ejecución para esperar la respuesta del usuario
                    }

                    if (serviceDetail?.indOrigen === 'E') {
                        if (infoUsuarioFormLight?.categoria !== "D") {
                            setAdmissionData(infoUsuarioFormLight);
                            setdataUserNotFound(infoUsuarioFormLightData);
                            if (serviceDetail.campoJson.servicioIngles === "S" && serviceDetail.campoJson.tieneEntrevista === "S") {
                                if (serviceDetail?.campoJson?.totpFormularioLight == "S") {
                                    setStep(4);
                                } else {
                                    setStep(6);
                                }
                            } else {
                                if (serviceDetail?.campoJson?.totpFormularioLight == "S") {
                                    setStep(4);
                                } else {
                                    setStep(5);
                                }
                            }

                        } else {
                            setdataUserNotFound(infoUsuarioFormLightData);
                            if (serviceDetail.campoJson.servicioIngles === "S" && serviceDetail.campoJson.tieneEntrevista === "S") {
                                ////console.log('entrevista ingles')
                                setAdmissionData(infoUsuarioFormLight);
                                setStep(6)
                            } else {
                                setStep(2)
                            }
                        }

                    } else {
                        if (infoUsuarioFormLight?.categoria !== "D") {
                            setAdmissionData(infoUsuarioFormLight);
                            if (serviceDetail?.campoJson?.totpFormularioLight == "S") {
                                setStep(4);
                            } else {
                                setStep(5);
                            }
                        } else {
                            setdataUserNotFound(infoUsuarioFormLightData);
                            setStep(2);
                        }
                    }
                }
            } else setLoading && setLoading(false);
        }
    };

    const { getParamData } = useParam();

    const handleConfirmPayment = async () => {
        // Aquí envías la notificación de pago y abres la pasarela
        console.log("Enviando notificación de pago y abriendo pasarela...");
    
        const getParamUrlPasarela = await getParamData("URL_PASARELA");

        const paySaleResultInfo = await paySale(idCompra);

        if (getParamUrlPasarela?.data && paySaleResultInfo) {
            const dataParameter = getParamUrlPasarela?.data?.valorParametro;
            window.location.replace(dataParameter + paySaleResultInfo?.infoPago);
        }
        setOpenDialog(false);
    };

    const handleCloseDialog = () => {
        // Aquí se limpia y se deja en el formulario de autogestión
        setOpenDialog(false);
    };

    return (
        <>
            <CustomDialog
                open={openDialog}
                onClose={handleCloseDialog}
                onConfirm={handleConfirmPayment}
                title="Servicio ya adquirido"
                message={mensajeServicioAdquirido}
                messageOnClose="No"
                messageOnConfirm="Sí"
                onlyClose={false}
            />
            <div className="w-full ">
                <span className="font-regular text-xl text-principal-250 flex justify-center items-center">
                    Solicita este producto en instantes
                </span>

                <span className="w-full font-bold sm:w-full px-1 py-3 text-principal-250 flex">
                    Ingresa los datos quien va a tomar el servicio:
                </span>

                <form
                    onSubmit={handleSubmit(handleFormSubmit)}
                    className="flex flex-col"
                >
                    <div className="w-full sm:w-full px-1 py-3 ">
                        <FormControl className="w-full">
                            <InputLabel id="tipo-identificacion">
                                Tipo de identificación
                            </InputLabel>
                            <Select
                                labelId="tipo-identificacion"
                                label="Tipo de identificación"
                                defaultValue={""}
                                {...register(`documentType`, {
                                    required: "El tipo de identificación es obligatorio",
                                })}
                            >
                                {identificationTypes?.map((id, index) => (
                                    <MenuItem key={index} value={id?.ABREVIATURA}>
                                        {id?.DESCRIPCION}
                                    </MenuItem>
                                ))}
                            </Select>
                            {errors?.documentType && (
                                <p className="text-red-500">
                                    {String(errors?.documentType?.message)}
                                </p>
                            )}
                        </FormControl>
                    </div>
                    <div className="w-full sm:w-full px-1 py-3 ">
                        <TextField
                            defaultValue={""}
                            className="w-full"
                            label="Número de documento"
                            {...register(`documentNumber`, {
                                required: "El número de documento es obligatorio",
                            })}
                        />
                        {errors?.documentNumber && (
                            <p className="text-red-500">
                                {String(errors?.documentNumber?.message)}
                            </p>
                        )}
                    </div>

                    <div className="w-full px-1 py-2">
                        <FormControlLabel
                            required
                            control={
                                <div className="flex items-center">
                                    <Checkbox required />
                                    <div className="">
                                        <span>Acepto el</span>{' '}
                                        <span className="text-principal-250">tratamiento de datos personales</span>
                                    </div>
                                </div>
                            }
                            label=""
                        />
                    </div>

                    <div className='flex justify-center w-full py-2'>
                        <LoadingButton
                            type="submit"
                            variant="contained"
                            className="w-full bg-principal-250"
                            loading={false}
                            disabled={disableButton}
                        >
                            Solicitar producto
                        </LoadingButton>
                    </div>

                </form>

                <Dialog
                    open={open}
                    onClose={handleClose}
                >
                    <DialogTitle>Error</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {errorMessage}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancelar</Button>
                    </DialogActions>
                </Dialog>
            </div>

        </>
    )
}
